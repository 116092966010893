img {
    width: 100%;
}

.navbar {
    background-color: #e3f2fd;
}

.fa.fa-edit {
    color: #18a2b9;
}

.list-group-item.delete:hover {
    cursor: -webkit-grab;
    background-color: pink;
}

.list-group-item.update:hover {
    cursor: -webkit-grab;
    background-color: gainsboro;
}

.list-group-item.board:hover {
    cursor: -webkit-grab;
    background-color: gainsboro;
}

.fa.fa-minus-circle {
    color: red;
}

.landing {
    position: relative;
    /* background: url("../img/showcase.jpg") no-repeat; */
    background-size: cover;
    background-position: center;
    /* height: 100vh; */
    margin-top: -24px;
    margin-bottom: -50px;
}

.landing-inner {
    padding-top: 80px;
}

.dark-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.card-form {
    opacity: 0.9;
}

.latest-profiles-img {
    width: 40px;
    height: 40px;
}

.form-control::placeholder {
    color: #bbb !important;
}

.show {
    height: 80px;
}

footer {
    width: 100%;
    display: flex !important;
    position: absolute;
    bottom: 2px;
    justify-content: center;
}

.footer {
    text-align: center;
}

.footer p {
    line-height: 10px;
}

.footer a {
    color: #333333;
}

.footer a:hover {
    color: #333333;
}